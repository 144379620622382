// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { PreferencesService } from 'app/core/services/preferences.service';
import { TestsService } from 'app/core/services/tests.service';
import { TestFormService } from 'app/core/services/test-form.service';
import { AuthService } from 'app/core/services/auth.service';
import { DevicesService } from 'app/core/services/devices.service';
import { OperatorsService } from 'app/core/services/operators.service';
import { WebAuthnService } from '../../core/services/webauthn.service';
import { ErrorService } from 'app/core/services/error.service';
import { ZeroPiiService } from 'app/core/services/zero-pii.service';
import { DialogService } from 'app/core/services/dialog.service';
import { PatientsJobService } from 'app/core/services/patients-encrypt-job.service';
import { LanguageService } from 'app/core/services/language.service';

// model
import {
  Authenticator, Device, Tab, UserPreferences, Operator, UserPreferencesPutRequest, CenterContainerTitle,
  TestLanguage, TestGroup, TestStrategy, TestProtocol, EomsAppearance
} from '../../models';

// constants
import { EOMS_APPEARANCES } from '../../constants';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html'
})
export class PreferencesComponent implements OnInit, OnDestroy {

  preferences: UserPreferences;
  tabs: Tab[] = [];
  activeTab: number = 0;
  title: CenterContainerTitle = {};
  form: UntypedFormGroup;
  isLoading: boolean = false;
  languageSubscription: Subscription;

  devices: Device[] = [];
  operators: Operator[] = [];
  eomsAppearances: EomsAppearance[] = EOMS_APPEARANCES;

  allTestGroups: TestGroup[] = [];
  displayedTestGroups: TestGroup[] = [];
  displayedTestStrategies: TestStrategy[] = [];
  displayedTestProtocols: TestProtocol[] = [];

  allCustomBundleGroups: TestGroup[] = [];
  displayedCustomBundleGroups: TestGroup[] = [];

  displayedTestLanguages: TestLanguage[] = [];

  authenticators: Authenticator[] = [];

  isZeroPiiFormVisible: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private preferencesService: PreferencesService,
    private testsService: TestsService,
    private testFormService: TestFormService,
    private toastService: ToastrService,
    private operatorsService: OperatorsService,
    public devicesService: DevicesService,
    public authService: AuthService,
    private translateService: TranslateService,
    private webAuthnService: WebAuthnService,
    private errorService: ErrorService,
    private zeroPiiService: ZeroPiiService,
    private dialogService: DialogService,
    private patientsJobService: PatientsJobService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.setTabs();
    this.translateService.get('preferences').subscribe(() => {
      this.setTranslations();
    });

    this.isLoading = true;
    forkJoin([
      this.testsService.getTestGroups(),
      this.preferencesService.get(),
      this.devicesService.getAllWithAvailableTests(),
      this.operatorsService.getAll(),
      this.testsService.getAllTestLanguages()
    ]).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(([testGroups, preferences, devices, operators]) => {
      this.devices = devices;
      this.operators = operators;

      this.allTestGroups = (<TestGroup[]>testGroups).filter(group => !group.custom_test_bundle_info);
      this.allCustomBundleGroups = (<TestGroup[]>testGroups).filter(group => !!group.custom_test_bundle_info);

      this.preferences = preferences;

      this.initForm(preferences);
    }, error => this.errorService.handleError(error));

    this.fetchAuthenticators();
  }

  ngOnDestroy(): void {
    this.testFormService.hideGroupVisualHint();
    this.testFormService.hideStrategyVisualHint();
    this.testFormService.hideLanguageVisualHint();
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('preferences');
    this.title.thin = this.translateService.instant('user');
  }

  private setTabs() {
    this.tabs = [
      {
        translationKey: 'test',
        action: () => this.setActiveTab(0),
        isActive: true
      },
      {
        translationKey: 'report',
        action: () => this.setActiveTab(1),
        isActive: false
      },
      {
        translationKey: 'security',
        action: () => this.setActiveTab(2),
        isActive: false
      },
      {}
    ];

    if (this.authService.isDemoModeAvailable) {
      const length = this.tabs.length;
      this.tabs[3] = {
        translationKey: 'demoMode',
        action: () => this.setActiveTab(3),
        isActive: false
      };
    }

    const preselectedTab = parseInt(this.route.snapshot.queryParams?.tab);
    if (preselectedTab && this.tabs[preselectedTab]?.translationKey) {
      this.activeTab = preselectedTab;
      this.tabs[0].isActive = false;
      this.tabs[preselectedTab].isActive = true;
    }
  }

  initForm(preferences: UserPreferences) {
    this.form = this.formBuilder.group({
      skip_tutorial: [preferences.skip_tutorial],
      skip_convergence_testing: [preferences.skip_convergence_testing],
      use_automated_convergence: [preferences.use_automated_convergence],
      use_short_tutorial: [preferences.use_short_tutorial],
      group: [!!preferences.use_custom_test_bundle_type ? this.testFormService.customBundleGroupValue : preferences.test_group?.group?.value],
      strategy: [preferences.test_group?.strategy?.value],
      protocol: [preferences.test_group?.protocol?.value],
      custom_test_bundle_type: [preferences.custom_test_bundle_type],
      language: [preferences.language],
      disable_pause: [preferences.disable_pause],
      device: [this.devices.find(dev => dev.id === preferences.device?.id)],
      operator: [preferences.operator?.id],
      enable_trigger_haptic_feedback: [preferences.enable_trigger_haptic_feedback],
      enable_dynamic_matrix: [preferences.enable_dynamic_matrix],
      enable2fa: [preferences.enable2fa],
      enable_demo_mode: [preferences.enable_demo_mode],
      eoms_appearance: [preferences.eoms_appearance],
      max_external_low_confidence_sensitivities: [preferences.max_external_low_confidence_sensitivities],
      enable_webauthn: [!!this.route?.snapshot?.queryParams?.authenticator || preferences.enable_webauthn],
      system_unit: [preferences.system_unit],
      always_use_subtitles: [preferences.always_use_subtitles],
      enable_access_to_other_offices: [{ value: preferences.enable_access_to_other_offices, disabled: !!preferences.enable_zero_pii }],
      use_advanced_calibration: [preferences.use_advanced_calibration],
      enable_zero_pii: [{ value: preferences.enable_zero_pii, disabled: !!preferences.enable_zero_pii || !preferences.enable_access_to_other_offices }],
      zero_pii_password: [null],
      enable_opv_dicom: [preferences.enable_opv_dicom],
      include_threshold_vf_test_in_single_page_bundle_report: [preferences.include_threshold_vf_test_in_single_page_bundle_report],
      separate_report_for_each_eye: [preferences.separate_report_for_each_eye],
      report_language: [preferences.report_language],
      date_format: [preferences.date_format],
      time_format: [preferences.time_format],
      use_headset_alignment: [preferences.use_headset_alignment],
      user_inactivity_limit_seconds: [preferences.user_inactivity_limit_seconds || 0],
      enable_notifications_on_home_test_completion: [preferences.enable_notifications_on_home_test_completion],
      use_monocular_for_contrast_sensitivity: [preferences.use_monocular_for_contrast_sensitivity],
      force_2fa: [preferences.force_2fa]
    });

    this.form.get('force_2fa').valueChanges.subscribe(() => {
      this.onForce2FAChange();
    });

    this.form.get('group').valueChanges.subscribe(() => {
      this.onTestGroupChange();
    });
    this.form.get('strategy').valueChanges.subscribe(() => {
      this.onTestStrategyChange();
    });
    this.form.get('device').valueChanges.subscribe((device: Device) => {
      this.onDeviceChange(device);
    });

    this.onDeviceChange(this.form.controls.device?.value);

    // forces triggering strategy and/or protocol logic for initial test group selection
    this.onTestGroupChange(true);
    this.onForce2FAChange();
  }

  private onTestGroupChange(isInitialSet: boolean = false) {
    this.setStrategyDisplay(isInitialSet);
    this.setProtocolDisplay(isInitialSet);
    this.testFormService.setCustomBundleDisplay(this.form, isInitialSet);
    this.setLanguageDisplay()
  }

  private setLanguageDisplay() {
    this.displayedTestLanguages = this.testFormService.getDisplayedLanguages(this.selectedDevice, this.selectedTestGroup, this.selectedTestStrategy);
    this.checkSelectedLanguageValidity();
  }

  private setStrategyDisplay(isInitialSet: boolean = false) {
    this.displayedTestStrategies = this.selectedTestGroup ? this.selectedTestGroup.strategies?.slice(0) : [];

    if (!this.displayedTestStrategies?.length) {
      this.form.controls['strategy'].disable();
      this.form.controls['strategy'].setValue(null);
    } else {
      this.form.controls['strategy'].enable();
      this.form.controls['strategy'].setValue(isInitialSet ? this.preferences.test_group?.strategy?.value : null);
    }
  }

  private setProtocolDisplay(isInitialSet: boolean = false) {
    const protocols = this.selectedTestGroup?.protocols?.length ? this.selectedTestGroup.protocols : this.selectedTestStrategy?.protocols;
    this.displayedTestProtocols = protocols || [];

    if (!this.displayedTestProtocols?.length) {
      this.form.controls['protocol'].disable();
      this.form.controls['protocol'].setValue(null);
    } else if (this.displayedTestProtocols.length === 1) {
      this.form.controls['protocol'].enable();
      this.form.controls['protocol'].setValue(this.displayedTestProtocols[0].value);
    } else {
      this.form.controls['protocol'].enable();
      this.form.controls['protocol'].setValue(isInitialSet ? this.preferences.test_group?.protocol?.value : null);
    }
  }

  private onTestStrategyChange() {
    this.setProtocolDisplay();
    this.setLanguageDisplay();
  }

  onDeviceChange(device: Device) {
    const hadGroupSelected = !!this.selectedTestGroup;
    const hadStrategySelected = !!this.selectedTestStrategy;

    this.displayedTestGroups = device?.capabilities?.supported_test_groups ? device.capabilities.supported_test_groups.filter(group => !group.custom_test_bundle_info) : this.allTestGroups;
    this.displayedTestStrategies = this.selectedTestGroup?.strategies;

    this.displayedTestProtocols = this.selectedTestGroup?.protocols?.length ? this.selectedTestGroup.protocols : this.selectedTestStrategy?.protocols;
    if (this.displayedTestProtocols?.length === 1)
      this.form.controls['protocol'].setValue(this.displayedTestProtocols[0].value);

    this.setLanguageDisplay();

    this.displayedCustomBundleGroups = device?.capabilities?.supported_test_groups ? device.capabilities.supported_test_groups.filter(group => !!group.custom_test_bundle_info) : this.allCustomBundleGroups;
    if (this.testFormService.areCustomBundlesDisplayed) {
      this.testFormService.handleCustomBundleDisplayOnDeviceChange(this.form, this.displayedCustomBundleGroups);
      return;
    }

    this.testFormService.resetSelectionAndShowHint(this.form, this.selectedTestGroup, this.selectedTestStrategy, hadGroupSelected, hadStrategySelected);
  }

  private checkSelectedLanguageValidity() {
    if (!this.form.controls.language?.value)
      return;
    const currentLanguageValid = !!this.displayedTestLanguages.find(lang => lang.value === this.form.controls.language.value);
    if (!currentLanguageValid) {
      this.form.controls.language.setValue(null);
      this.testFormService.showLanguageVisualHint();
    }
  }

  getGroupByValue(value: string): TestGroup {
    return this.displayedTestGroups.find(group => group.value === value);
  }

  getStrategyByValue(value: string): TestStrategy {
    return this.selectedTestGroup?.strategies?.find(strategy => strategy.value === value);
  }

  get selectedTestGroup() {
    const selectedGroupValue = this.form.controls['group'].value;
    const selectedGroup = this.getGroupByValue(selectedGroupValue);
    return selectedGroup;
  }

  get selectedTestStrategy() {
    const selectedStrategyValue = this.form.controls['strategy'].value;
    const selectedStrategy = this.getStrategyByValue(selectedStrategyValue);
    return selectedStrategy;
  }

  get selectedDevice(): Device {
    return this.form.controls['device']?.value;
  }

  setActiveTab(index: number) {
    this.activeTab = index;
    this.tabs.forEach((tab: Tab, i: number) => {
      tab.isActive = i === index;
    });
  }

  close() {
    this.router.navigate(['/patients'], { queryParams: null });
  }

  async onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    let {
      skip_tutorial, skip_convergence_testing, use_automated_convergence, use_short_tutorial, group, strategy, protocol,
      language, disable_pause, enable2fa, operator, device, enable_demo_mode, enable_trigger_haptic_feedback,
      enable_dynamic_matrix, eoms_appearance, custom_test_bundle_type, max_external_low_confidence_sensitivities,
      enable_webauthn, system_unit, always_use_subtitles, enable_access_to_other_offices, use_advanced_calibration,
      enable_zero_pii, zero_pii_password, enable_opv_dicom, include_threshold_vf_test_in_single_page_bundle_report,
      report_language, date_format, time_format, use_headset_alignment, user_inactivity_limit_seconds, separate_report_for_each_eye,
      enable_notifications_on_home_test_completion, use_monocular_for_contrast_sensitivity, force_2fa
    } = this.form.value;

    if (enable_webauthn) {
      const authenticators = await this.webAuthnService.getAll().toPromise();
      if (!authenticators?.length)
        return this.toastService.error(this.translateService.instant('enableHardwareAuthErrorMessage'));
    }

    const zero_pii_password_hash = (enable_zero_pii && zero_pii_password)
      ? await this.zeroPiiService.hashPassword(zero_pii_password)
      : null;

    const body: UserPreferencesPutRequest = {
      skip_tutorial,
      skip_convergence_testing,
      use_automated_convergence,
      use_short_tutorial,
      test_group: {
        group: (group === this.testFormService.customBundleGroupValue) ? null : (group || null),
        strategy: strategy || null,
        protocol: protocol || null
      },
      use_custom_test_bundle_type: group === this.testFormService.customBundleGroupValue,
      custom_test_bundle_type: (group === this.testFormService.customBundleGroupValue) ? (custom_test_bundle_type || null) : null,
      language: this.displayedTestLanguages.find(lang => lang.value === language) ? language : null,
      disable_pause,
      enable2fa,
      enable_demo_mode,
      operator: operator || null,
      device: device?.id ? device.id : null,
      enable_trigger_haptic_feedback,
      enable_dynamic_matrix,
      eoms_appearance,
      max_external_low_confidence_sensitivities,
      enable_webauthn,
      system_unit,
      always_use_subtitles,
      use_advanced_calibration,
      enable_zero_pii,
      enable_opv_dicom,
      include_threshold_vf_test_in_single_page_bundle_report,
      use_headset_alignment,
      use_monocular_for_contrast_sensitivity,
    };

    if (this.authService.isOfficeGroupAdmin) {
      body.enable_access_to_other_offices = enable_access_to_other_offices;
      body.report_language = report_language;
      body.date_format = date_format;
      body.time_format = time_format;
      body.user_inactivity_limit_seconds = user_inactivity_limit_seconds || null;
      body.separate_report_for_each_eye = separate_report_for_each_eye;
      body.enable_notifications_on_home_test_completion = enable_notifications_on_home_test_completion;
      body.force_2fa = force_2fa;
    }

    if (zero_pii_password_hash)
      body.zero_pii_password_hash = zero_pii_password_hash;

    this.isLoading = true;
    this.preferencesService.update(body).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.close();
      if (res.device?.is_default_in_demo_account && res.enable_demo_mode)
        this.toastService.warning(this.translateService.instant('defaultDemoDeviceSelected'));
      if (res.enable_demo_mode)
        this.router.navigate(['demo-mode'], { queryParams: null });
      if (res.enable_zero_pii && zero_pii_password && zero_pii_password_hash && this.authService.isOfficeGroupAdmin) {
        this.zeroPiiService.saveKeyFromPassword(zero_pii_password);
        this.patientsJobService.encryptExistingPatients(true);
      }
    }, error => this.errorService.handleError(error));
  }

  onSkipConvergenceChange(event: any) {
    if (event.target?.checked)
      this.form.controls['use_automated_convergence'].setValue(false);
  }

  onAutomatedConvergenceChange(event: any) {
    if (event.target?.checked)
      this.form.controls['skip_convergence_testing'].setValue(false);
  }

  onShortTutorialChange(event: any) {
    if (event.target?.checked)
      this.form.controls['skip_tutorial'].setValue(false);
  }

  onSkipTutorialChange(event: any) {
    if (event.target?.checked)
      this.form.controls['use_short_tutorial'].setValue(false);
  }

  onEnableAccessChange(event: any) {
    if (!event.target?.checked) {
      this.form.controls['enable_zero_pii'].setValue(false);
      this.form.controls['enable_zero_pii'].disable();
      this.form.controls['zero_pii_password'].setValue(null);
    } else {
      this.form.controls['enable_zero_pii'].enable();
    }
  }

  onEnableDemoModeChange(event: any) {
    if (event.target?.checked) {
      this.form.controls['device'].setValidators([Validators.required]);
      this.form.controls['device'].updateValueAndValidity();
    }
    else {
      this.form.controls['device'].setValidators(null);
      this.form.controls['device'].updateValueAndValidity();
    }
  }

  fetchAuthenticators() {
    this.webAuthnService.getAll().subscribe((authenticators) => {
      this.authenticators = authenticators;
    });
  }

  addAuthenticator() {
    this.isLoading = true;
    this.webAuthnService.create().then((authenticator: Authenticator) => {
      this.toastService.success(this.translateService.instant('addDeviceWebAuthnSuccess'));
      this.fetchAuthenticators();
      this.updateAuthenticator(authenticator);
      this.isLoading = false;
    })
      .catch((error) => {
        this.errorService.handleError(this.translateService.instant('addDeviceWebAuthnError'));
        this.isLoading = false;
      });
  }

  updateAuthenticator(authenticator: Authenticator) {
    this.router.navigate(['authenticator/edit', authenticator.id], { queryParams: null });
  }

  deleteAuthenticator(authenticator: Authenticator) {
    this.webAuthnService.delete(authenticator.id).subscribe(res => {
      this.fetchAuthenticators();
    });
  }

  onZeroPiiChange(event) {
    if (event.target?.checked)
      this.dialogService.openConfirm({
        action: '',
        message: this.translateService.instant('zeroPiiConfirmDialogMessage'),
        confirmText: this.translateService.instant('yes'),
        cancelText: this.translateService.instant('no')
      }).then(result => {
        if (result.confirmed)
          this.isZeroPiiFormVisible = true;
        else
          this.form.controls['enable_zero_pii'].setValue(false);
      });
  }

  onZeroPiiKeySave(password: string) {
    this.isZeroPiiFormVisible = false;
    this.form.controls['zero_pii_password'].setValue(password);
  }

  onZeroPiiKeyCancel() {
    this.isZeroPiiFormVisible = false;
    this.form.controls['enable_zero_pii'].setValue(false);
  }

  onForce2FAChange() {
    if (this.form.controls['force_2fa'].value)
      this.form.controls['enable2fa'].disable();
    else
      this.form.controls['enable2fa'].enable();
  }
}
