// native
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

//addon
import { TranslateService } from '@ngx-translate/core';

// services
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService,
    private errorService: ErrorService
  ) { }

  public get(url: string): Observable<object | Array<object> | string> {
    return this.httpClient.get(url, { responseType: 'json' }).pipe(
      catchError(err => this.handleError(err))
    );
  }

  public getBlob(url: string): Observable<Blob | string> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      catchError(err => this.handleError(err))
    );
  }

  public downloadFile(url: string): Observable<void> {
    return this.httpClient.get(url, { responseType: 'blob', observe: 'response' }).pipe(
      map(response => {
        return {
          blob: response.body,
          filename: response.headers.get('content-disposition')?.split('filename="')[1]?.split('"')[0] || 'report'
        };
      }),
      tap(file => {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(file.blob);
        a.download = file.filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(a.href);
      }),
      map(file => undefined),
      catchError(err => this.handleError(err))
    );
  }

  public delete(url: string): Observable<object | Array<object> | string> {
    return this.httpClient.delete(url, { responseType: 'json' }).pipe(
      catchError(err => this.handleError(err))
    );
  }

  public put(url: string, body: object = null): Observable<object | Array<object> | string> {
    return this.httpClient.put(url, body, { responseType: 'json' }).pipe(
      catchError(err => this.handleError(err))
    );
  }

  public post(url: string, body: object = null, isTextResponse = false): Observable<object | Array<object> | string> {
    if (isTextResponse) {
      return this.httpClient.post(url, body, { responseType: 'text' }).pipe(
        catchError(err => this.handleError(err))
      );
    }
    return this.httpClient.post(url, body, { responseType: 'json' }).pipe(
      catchError(err => this.handleError(err))
    );
  }

  private handleError(errorResponse: Response | any): Observable<any> {
    // error can get from server in different forms
    const error: any = errorResponse?.error?.message
      || errorResponse?.error?.detail
      || errorResponse?.detail
      || errorResponse?.error;

    if (errorResponse?.status === 0)
      return throwError(this.translateService.instant('networkConnectionUnstable'));

    if ([500, 502, 504].includes(errorResponse?.status)) {
      this.errorService.handleServerErrors(error);
      return;
    }

    if ([401, 403].includes(errorResponse?.status)) {
      this.errorService.handleAuthErrors(error);
      this.authService.logout();
      return;
    }

    return throwError(error);
  }
}
