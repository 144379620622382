<div
  class="navigation_button"
  [ngClass]="{ 'navigation_button--expanded': expanded }"
  (click)="toggleMenu()">
    <span
      class="navigation_icon"
      [ngClass]="{ 'navigation_icon--expanded': expanded }">
    </span>
</div>

<div class="navigation_background" [ngClass]="{'navigation_background--expanded': expanded}"></div>
<nav class="navigation_navigation" [ngClass]="{'navigation_navigation--expanded': expanded}">
    <ul class="navigation_list">
      <li *ngIf="!authService.isTechallUserOrAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/user_settings']"
          (click)="expanded = false">
          {{ 'profile' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="!authService.isTechallUserOrAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/patients']"
          (click)="expanded = false">
          {{ 'patients' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="!authService.isTechallUserOrAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/devices']"
          (click)="expanded = false">
          {{ 'manageDevices' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="authService.isOfflineModeAvailable && !authService.isTechallUserOrAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/offline-controllers']"
          (click)="expanded = false">
          {{ 'manageOfflineControllers' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="authService.isOfficeGroupAdmin || (authService.isTechallAdmin && !!techallService.currentSession)" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/custom-bundles']"
          (click)="expanded = false">
          {{ 'manageCustomBundles' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="authService.isOfficeGroupAdmin || authService.isOfficeAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="[authService.isOfficeGroupAdmin ? '/offices' : '/users']"
          (click)="expanded = false">
          {{ authService.isOfficeGroupAdmin ? ('manageOffices' | translate | titlecase) : ('manageUsers' | translate | titlecase) }}
        </a>
      </li>
      <li *ngIf="authService.isTechallAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/waiting-sessions']"
          (click)="expanded = false">
          {{ 'manageSessions' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="authService.isPacsIntegrationUser && !authService.isTechallUserOrAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/work-items']"
          (click)="expanded = false">
          {{ 'manageWorkItems' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="authService.isSupportUser" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/support/devices']"
          (click)="expanded = false">
          {{ 'reports' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="!authService.isTechallUserOrAdmin" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/preferences']"
          (click)="expanded = false">
          {{ 'preferences' | translate | titlecase }}
        </a>
      </li>
      <li *ngIf="!authService.isTechallUser || !techallService.currentSession" class="navigation_item">
        <a
          class="navigation_link"
          [routerLink]="['/about']"
          (click)="expanded = false">
          {{ 'about' | translate | titlecase }}
        </a>
      </li>
      <li class="navigation_item">
        <a
          class="navigation_link"
          href
          (click)="logout($event)">
          {{ 'logout' | translate | titlecase }}
        </a>
      </li>
    </ul>
</nav>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
