//native
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

//service
import { AuthService } from 'app/core/services/auth.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { TechallSessionService } from 'app/core/services/techall-session.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {
  @Input() expanded = false;
  isLoading: boolean = false;

  constructor(
    public authService: AuthService,
    private navigationService: NavigationService,
    private router: Router,
    public techallService: TechallSessionService
  ) { }

  toggleMenu() {
    this.expanded = !this.expanded;

    if (this.expanded && this.router.url.includes('demo-mode')) {
      this.navigationService.navigationOpened$.next();
      this.navigationService.navigationHidden$.next(true);
    }
  }

  logout(event: Event) {
    this.expanded = false;
    this.isLoading = true;
    event.preventDefault();
    this.authService.logout().finally(() => this.isLoading = false);
  }
}