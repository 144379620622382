// native
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { PlotData, EyeStats, EomsRecord, MonitorEvent } from '../../models';

// constant
import { EYE, MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT, STRATEGY } from '../../constants';

const SIX_DIRECTIONS_COUNT = 6;
const EIGHT_DIRECTIONS_COUNT = 8;

@Component({
  selector: 'app-monitor-eoms',
  templateUrl: './monitor-eoms.component.html'
})
export class MonitorEomsComponent extends MonitorBaseComponent {
  odData: PlotData[] = [];
  layoutRight;
  osData: PlotData[] = [];
  layoutLeft;

  odEyeStats: EyeStats = new EyeStats();
  osEyeStats: EyeStats = new EyeStats();

  odCount = 0;
  osCount = 0;

  directionsCount = EIGHT_DIRECTIONS_COUNT;
  osDirectionsProcessed = new Set();
  odDirectionsProcessed = new Set();

  public inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.EOMS * 1000;

  @ViewChild('monitorContainer') monitorContainer: ElementRef;
  @ViewChild('plotContainer') plotContainer: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService,
      patientsService, monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.odData = [{ ...this.monitorTestService.getEomsInitialPlotData() }];
    this.osData = [{ ...this.monitorTestService.getEomsInitialPlotData() }];

    if (this.test.test_group?.strategy?.value === STRATEGY.EOMS_SENSORIMOTOR)
      this.directionsCount = EIGHT_DIRECTIONS_COUNT;
    else {
      const algorithmVersion = this.device?.capabilities?.eoms_algorithm_version || 0;
      this.directionsCount = algorithmVersion > 0 ? EIGHT_DIRECTIONS_COUNT : SIX_DIRECTIONS_COUNT;
    }

    this.monitorTestService.getEomsTestRecords(this.test.id).subscribe(records => {
      this.mergeCurrentState(records);
      this.calculateCompletionPercentage();
    }, error => this.toastService.error(error, 'Error'));
  }

  private mergeCurrentState(records: EomsRecord[]) {
    records.forEach(record => this.mergeRecord(record));
  }

  private mergeRecord(record: EomsRecord) {
    // only update is used for EOMS because we already have starting coordinates for all positions (we show placeholders at start)
    this.updateRecord(record.direction, record);

    if (record.eye === EYE.OD) {
      this.odCount++;
    } else {
      this.osCount++;
    }

    this.updateStats(record);
  }

  private updateRecord(direction: number, record: EomsRecord) {
    let existingSnapshot: PlotData = record.eye === EYE.OD ? { ...this.odData[0] } : { ...this.osData[0] };

    const color = 2;

    const newShapshot = { ...existingSnapshot };

    if (record.angle)
      newShapshot.text[direction] = (record.angle.toString() + '&#176;');

    // Mirror the X coordinate to match how doctor sees the eye.
    const mirroredX = -record.x;

    if (record.attempt === 1 || record.attempt === 2) {
      newShapshot.marker.color[direction] = color;
      newShapshot.x[direction] = mirroredX;
      newShapshot.y[direction] = record.y;
    } else if (record.attempt === 3 || record.attempt === 4) {
      newShapshot.marker.color[direction + this.directionsCount] = color;
      newShapshot.x[direction + this.directionsCount] = mirroredX;
      newShapshot.y[direction + this.directionsCount] = record.y;
    }

    const updatedSnapshot = {
      ...newShapshot,
      marker: {
        ...newShapshot.marker,
        color: [...newShapshot.marker.color]
      },
      x: [...newShapshot.x],
      y: [...newShapshot.y],
      text: [...newShapshot.text]
    };

    if (record.eye === EYE.OD)
      this.odData = [updatedSnapshot];
    else
      this.osData = [updatedSnapshot];
  }


  private updateStats(record: EomsRecord) {
    let eye = record.eye;
    let eyeStats: EyeStats;

    if (eye === EYE.OD)
      eyeStats = this.odEyeStats;
    else
      eyeStats = this.osEyeStats;

    if (eyeStats.totalLocationCount === 0)
      eyeStats.totalLocationCount = this.directionsCount;

    if (eye === EYE.OD) {
      this.odDirectionsProcessed.add(record.direction);
      eyeStats.totalProcessedCount = this.odDirectionsProcessed.size;
    } else {
      this.osDirectionsProcessed.add(record.direction);
      eyeStats.totalProcessedCount = this.osDirectionsProcessed.size;
    }

    this.calculateCompletionPercentageForEye(eyeStats);
  }

  private calculateCompletionPercentageForEye(eyeStats: EyeStats) {
    eyeStats.completionPercentage = eyeStats.totalLocationCount > 0
      ? Math.min(eyeStats.totalProcessedCount / eyeStats.totalLocationCount * 100, 100)
      : 0;
  }

  private calculateCompletionPercentage() {
    this.calculateCompletionPercentageForEye(this.odEyeStats);
    this.calculateCompletionPercentageForEye(this.osEyeStats);
  }

  public onToggleGrid() { }

  public calculateDimensions() {
    const dimensions = this.monitorTestService.getDoublePlotDimensions(this.monitorContainer.nativeElement, this.plotContainer.nativeElement);

    this.layoutLeft = { ...this.monitorTestService.eomsLayout, ...dimensions };
    this.layoutRight = { ...this.monitorTestService.eomsLayout, ...dimensions };
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_EOMS_RECORD)
      this.mergeRecord(<EomsRecord>event.data);
  }

  public resetStateAfterReconnection(): void {
    this.odEyeStats = {
      ...this.odEyeStats,
      blindspotCount: 0,
      falsePositiveCount: 0,
      falseNegativeCount: 0,
      totalFalseNegativeTestsCount: 0,
      totalLocationCount: 0,
      totalViewedCount: 0,
      totalProcessedCount: 0
    };
    this.osEyeStats = {
      ...this.osEyeStats,
      blindspotCount: 0,
      falsePositiveCount: 0,
      falseNegativeCount: 0,
      totalFalseNegativeTestsCount: 0,
      totalLocationCount: 0,
      totalViewedCount: 0,
      totalProcessedCount: 0
    };

    this.odCount = 0;
    this.osCount = 0;

    this.osDirectionsProcessed = new Set();
    this.odDirectionsProcessed = new Set();
  }
}
