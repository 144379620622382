<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column" (window:resize)="windowChanged.emit(getEvent())" *ngIf="isPlotShown">
    <div class="monitor_graph-box" #plotContainer>
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>

      <plotly-plot [data]="odData" *ngIf="layoutRight" [layout]="layoutRight" [config]="monitorTestService.plotOptions"
        [revision]="odCount" [debug]="true" class="monitor_plot-box">
      </plotly-plot>
    </div>
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>

      <plotly-plot [data]="osData" *ngIf="layoutLeft" [layout]="layoutLeft" [config]="monitorTestService.plotOptions"
        [revision]="osCount" [debug]="true" class="monitor_plot-box">
      </plotly-plot>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <app-percentage-circle
        id="test-percentage-right"
        class="monitor_circle-box" 
        [ngClass]="{ 'monitor_circle-box--no-plot': !isPlotShown }"
        [percentage]="odEyeStats.completionPercentage?.toFixed(0)">
      </app-percentage-circle>
    </div>
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <app-percentage-circle
        id="test-percentage-left"
        class="monitor_circle-box" 
        [ngClass]="{ 'monitor_circle-box--no-plot': !isPlotShown }"
        [percentage]="osEyeStats.completionPercentage?.toFixed(0)">
      </app-percentage-circle>
    </div>
  </div>
</div>