// native
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { DevicesService } from 'app/core/services/devices.service';
import { DoctorsService } from 'app/core/services/doctors.service';
import { AuthService } from 'app/core/services/auth.service';
import { OperatorsService } from 'app/core/services/operators.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { PatientsService } from 'app/core/services/patients.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { TestFormService } from 'app/core/services/test-form.service';
import { TestsRecurringService } from 'app/core/services/tests-recurring.service';
import { TestsService } from 'app/core/services/tests.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { UtilityService } from 'app/core/services/utility.service';
import { TestDefaultService } from 'app/core/services/test-default.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { TestFormComponent } from '../../tests/test-form/test-form.component';

// models
import { Device, PendingTestBundleItem, RecurringTestPostRequest, Test, TestBundleRequest, TestDescription } from '../../models';

// constant
import { SUPRA_T_INTENSITIES, EYE, GROUP, CONTROL_TYPES, DEFAULT_OCCLUSION_TIME, TESTING_POSITIONS } from '../../constants';

@Component({
  selector: 'app-bundle-form',
  templateUrl: './bundle-form.component.html'
})
export class BundleFormComponent extends TestFormComponent {

  @Input() bundledTests: Test[] | PendingTestBundleItem[] = [];

  currentBundleStep: number = 1;
  newTestPromptShown: boolean = false;
  bundleRequestModel: TestBundleRequest;

  direction: 'back' | 'forward' = null;
  isResetItemsMessageDisplayed: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public devicesService: DevicesService,
    public doctorsService: DoctorsService,
    public operatorsService: OperatorsService,
    public patientsService: PatientsService,
    public testsService: TestsService,
    public testsRecurringService: TestsRecurringService,
    public toastService: ToastrService,
    public testFormService: TestFormService,
    public router: Router,
    public authService: AuthService,
    public preferencesService: PreferencesService,
    public testBundlesService: TestBundlesService,
    public translateService: TranslateService,
    public monitorTestService: MonitorTestService,
    public utilityService: UtilityService,
    public testDefaultService: TestDefaultService,
    public errorService: ErrorService,
    public dialogService: DialogService
  ) {
    super(formBuilder, devicesService, doctorsService, operatorsService, patientsService, testsService, testsRecurringService,
      toastService, testFormService, router, authService, preferencesService, testBundlesService, translateService,
      monitorTestService, utilityService, testDefaultService, errorService, dialogService);
  }

  public setTranslations(): void {
    this.title.bold = this.translateService.instant('testBundle');
  }

  onDeviceChange(device: Device) {
    if (this.isNew)
      this.resetPendingBundleItems();
    super.onDeviceChange(device);
  }

  onLanguageChange(language: string) {
    if (this.isNew)
      this.resetPendingBundleItems();
  }

  onBundleItemSave(direction: 'back' | 'forward') {
    this.removeHintMessages();
    this.direction = direction;

    if (this.isNew && this.testFormService.shouldShowConfirmModal(this.form, this.controlTypes, this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol)) {
      this.dialogService.openConfirm({
        action: this.translateService.instant('create'),
        message: this.translateService.instant('voiceConfirmMessage'),
        confirmText: this.translateService.instant('yes'),
        cancelText: this.translateService.instant('no'),
        showClose: false
      }).then(result => {
        if (result.confirmed)
          this.bundleItemSave(direction);
        if (result.canceled)
          this.cancelCreate();
      });
    } else {
      this.bundleItemSave(direction);
    }
  }

  private bundleItemSave(direction: 'back' | 'forward') {
    if (direction === 'back') {
      this.updateBundledTests();
      this.currentBundleStep--;
      this.populateFormForStep();
      return;
    }

    if (direction === 'forward' && this.isLastStep) {
      this.updateBundledTests();
      this.newTestPromptShown = true;
    } else if (direction === 'forward') {
      this.updateBundledTests();
      this.currentBundleStep++;
      this.populateFormForStep();
    }
  }

  private updateBundledTests(): void {
    if (this.isDisabled)
      return;

    const { device, doctor, operator, group, strategy, protocol, with_correction, correction_type, eye, supra_t_intensity, goldman_size, control_type, language,
      monocular, skip_convergence_testing, skip_tutorial, use_short_tutorial, disable_pause, test_foveal_sensitivity, stimulus_type, apd_stimulation_time,
      skip_calibration, skip_eye_tracking_calibration, subtitle_increase_text_size, subtitles, occlusion_time, cover_directions, cover_digitally_occluded } = this.form.value;

    const item: PendingTestBundleItem = {
      patient: (<Test | PendingTestBundleItem>this.test)?.patient,
      device: device.id,
      doctor: { id: doctor },
      operator: { id: operator },
      language,
      test_group: {
        group: { value: group, control_type: this.selectedTestGroup?.control_type, voice_input_languages: this.selectedTestGroup?.voice_input_languages },
        strategy: strategy ? { value: strategy, control_type: this.selectedTestStrategy?.control_type, voice_input_languages: this.selectedTestStrategy?.voice_input_languages } : null,
        protocol: protocol ? { value: protocol, control_type: this.selectedTestProtocol?.control_type, voice_input_languages: this.selectedTestProtocol?.voice_input_languages } : null
      },
      with_correction,
      correction_type: this.correctionTypes?.map(type => type.id).includes(correction_type) ? correction_type : null,
      eye: this.testFormService.calculateEyePayload(this.selectedTestGroup, this.selectedTestStrategy, this.selectedDevice, this.selectedMonocular, eye),
      supra_t_intensity: this.testFormService.calculateIntensityPayload(this.selectedTestStrategy, this.selectedTestProtocol, this.selectedDevice, supra_t_intensity),
      goldman_size: this.testFormService.calculateGoldmanSizePayload(this.selectedTestStrategy, goldman_size),
      control_type,
      monocular: this.testFormService.calculateMonocularPayload(skip_convergence_testing, monocular, this.selectedTestGroup, this.selectedTestStrategy, this.selectedEye, this.selectedDevice),
      skip_convergence_testing: this.testFormService.isTestWithConvergence(this.selectedTestStrategy) ? skip_convergence_testing : true,
      skip_tutorial: this.testFormService.isTestWithTutorial(this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol) ? skip_tutorial : true,
      use_short_tutorial: this.testFormService.isMainLanguage(language) ? use_short_tutorial : true,
      disable_pause: this.testFormService.isTestWithDisablePause(this.selectedDevice, this.selectedTestStrategy) ? disable_pause : true,
      test_foveal_sensitivity: this.testFormService.isTestWithFoveal(this.selectedTestStrategy, this.selectedDevice) ? test_foveal_sensitivity : false,
      stimulus_type: this.testFormService.isTestWithStimulusType(this.selectedTestStrategy) ? stimulus_type : null,
      apd_stimulation_time: this.testFormService.isTestWithApdStimulationTime(this.selectedTestStrategy) ? apd_stimulation_time : null,
      skip_calibration: this.testFormService
        .isTestWithSkipCalibration(this.selectedTestGroup, this.selectedTestStrategy, this.selectedTestProtocol, this.selectedControlType) ? !!skip_calibration : false,
      skip_eye_tracking_calibration: this.testFormService
        .isTestWithSkipEyeTrackingCalibration(this.selectedTestGroup, this.selectedTestStrategy) ? !!skip_eye_tracking_calibration : null,
      subtitles: this.testFormService
        .isTestWithSubtitles(this.selectedDevice, this.selectedLanguage, this.selectedTestGroup, this.selectedTestStrategy)
        ? this.preferences.always_use_subtitles ? true : subtitles
        : false,
      occlusion_time: this.testFormService.calculateOcclusionTimePayload(this.selectedTestGroup, this.selectedTestStrategy, occlusion_time),
      cover_directions: this.testFormService.isTestWithPositions(this.selectedTestStrategy) ? cover_directions : null,
      cover_digitally_occluded: this.testFormService.isTestWithDefaultDigitalOcclusion(this.selectedTestStrategy) ? cover_digitally_occluded : false,
      subtitle_increase_text_size: this.testFormService.isTestWithIncreasedSubtitles(this.selectedDevice, this.selectedLanguage, this.selectedSubtitles, this.selectedTestGroup, this.selectedTestStrategy)
        ? subtitle_increase_text_size
        : false,
    };

    (<PendingTestBundleItem[]>this.bundledTests)[this.currentBundleStep - 1] = item;
  }

  onNextTestCreate() {
    this.form.controls['group'].markAsUntouched();
    this.form.controls['strategy'].markAsUntouched();

    this.isPending = false;

    this.form.controls['group'].setValue(this.preferences?.test_group?.group?.value ? this.preferences.test_group.group.value : null);

    this.setStrategyDisplay(true);
    this.setProtocolDisplay(true);

    this.form.controls.with_correction.setValue(false);
    this.form.controls.supra_t_intensity.setValue(SUPRA_T_INTENSITIES.STANDARD.value);
    this.form.controls.occlusion_time.setValue(DEFAULT_OCCLUSION_TIME);
    this.form.controls.cover_directions.setValue(TESTING_POSITIONS.PRIMARY_ONLY.value);
    this.form.controls.cover_digitally_occluded.setValue(false);

    this.currentBundleStep++;
    this.newTestPromptShown = false;
  }

  onSubmit(form: UntypedFormGroup, createRecurring = false) {
    this.bundleRequestModel = this.buildRequestModel();

    this.expandPostModelForCSuite();

    this.isLoading = true;
    this.testBundlesService.create(this.bundleRequestModel).subscribe(bundle => {
      if (!createRecurring) {
        this.isLoading = false;
        return this.monitorTestService.openMonitorScreen(bundle.current_test);
      }

      const recurringBody: RecurringTestPostRequest = {
        patient: this.patient.id,
        period_in_days: this.form.value.period_in_days,
        end_date: this.utilityService.convertClientDateToServerDate(this.form.value.end_date),
        test_bundle: bundle.id
      };

      this.testsRecurringService.create(recurringBody).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(res => {
        this.monitorTestService.openMonitorScreen(bundle.current_test);
      }, error => this.errorService.handleTestError(error));
    }, error => {
      this.isLoading = false;
      this.errorService.handleTestError(error);
    });
  }

  private buildRequestModel(): TestBundleRequest {
    const { device, doctor, operator } = this.bundledTests[0];
    const patient = this.patient.id || null;
    const test_descriptions: TestDescription[] = [];

    const firstConvergenceValue = this.getFirstConvergenceValue();
    const firstConvergenceMonocularValue = this.getFirstConvergenceMonocularValue();

    this.bundledTests.forEach(test => {
      test_descriptions.push({
        language: test.language,
        test_group: {
          group: test.test_group?.group?.value,
          strategy: test.test_group?.strategy?.value,
          protocol: test.test_group?.protocol?.value,
        },
        with_correction: test.with_correction,
        correction_type: test.correction_type,
        eye: test.eye,
        supra_t_intensity: test.supra_t_intensity,
        goldman_size: test.goldman_size,
        control_type: this.calculateControlType(test.control_type, test.test_group?.group, test.test_group?.strategy, test.test_group?.protocol),
        skip_convergence_testing: this.testFormService.isTestWithConvergence(test.test_group?.strategy) ? firstConvergenceValue : test.skip_convergence_testing,
        monocular: this.testFormService.calculateBundleMonocularPayload(test, firstConvergenceMonocularValue, this.selectedDevice),
        skip_tutorial: test.skip_tutorial,
        use_short_tutorial: test.use_short_tutorial,
        disable_pause: test.disable_pause,
        test_foveal_sensitivity: test.test_foveal_sensitivity,
        stimulus_type: test.stimulus_type,
        apd_stimulation_time: test.apd_stimulation_time,
        skip_calibration: test.skip_calibration,
        skip_eye_tracking_calibration: test.skip_eye_tracking_calibration,
        subtitles: test.subtitles,
        occlusion_time: test.occlusion_time,
        cover_directions: test.cover_directions,
        cover_digitally_occluded: test.cover_digitally_occluded,
        subtitle_increase_text_size: test.subtitle_increase_text_size,
      });
    });

    const body: TestBundleRequest = {
      patient,
      device,
      doctor: doctor.id,
      operator: operator.id,
      test_descriptions
    };

    if (this.mwlItemId)
      body.mwl_query_result_item = this.mwlItemId;

    return body;
  }

  hasConvergenceTestBeforeCurrentStep(stepIndex: number) {
    for (let i = 0; i < stepIndex; i++) {
      const test = this.bundledTests[i];
      if (this.testFormService.isTestWithConvergence(test.test_group?.strategy))
        return true;
      if (test.test_group?.group?.value == GROUP.CSUITE)
        return true;
    }
  }

  private getFirstConvergenceValue(): boolean {
    for (let i = 0; i < this.bundledTests.length; i++) {
      const test = this.bundledTests[i];
      if (this.testFormService.isTestWithConvergence(test.test_group?.strategy))
        return test.skip_convergence_testing;
      if (test.test_group?.group?.value == GROUP.CSUITE)
        return true;
    }
  }

  private getFirstConvergenceMonocularValue(): boolean {
    for (let i = 0; i < this.bundledTests.length; i++) {
      const test = this.bundledTests[i];
      if (this.testFormService.isTestWithConvergence(test.test_group?.strategy))
        return test.monocular;
      if (test.test_group?.group?.value === GROUP.CSUITE)
        return this.form?.value?.monocular_confrontation || false;
    }
    return null;
  }

  closeForm() {
    if (this.mwlItemId)
      return this.router.navigate(['/work-items']);

    return this.router.navigate(['/tests/bundles', this.patient ? this.patient.id : (<Test>this.test).patient.id]);
  }

  onBack() {
    if (this.isBackDisabled())
      return;

    if (this.newTestPromptShown)
      this.newTestPromptShown = false;
    else
      this.onBundleItemSave('back');
  }

  onForward() {
    if (this.isForwardDisabled())
      return;

    this.onBundleItemSave('forward');
  }

  private populateFormForStep() {
    this.test = this.bundledTests[this.currentBundleStep - 1];
    this.initForm(this.test, true);
    this.isVoiceWarningDisplayed = this.controlTypes.find(type => type.id === this.test.control_type)?.name === CONTROL_TYPES.VOICE;
  }

  isConvergenceSectionVisible() {
    if (this.isNew && this.hasConvergenceTestBeforeCurrentStep(this.currentBundleStep - 1))
      return false;

    return this.testFormService.isTestWithConvergence(this.selectedTestStrategy);
  }

  private expandPostModelForCSuite() {
    const csuiteIndexes = [];

    this.bundleRequestModel.test_descriptions.forEach((description, index) => {
      if (description.test_group?.group === GROUP.CSUITE)
        csuiteIndexes.push(index);
    });

    const device: Device = this.form.controls['device']?.value;
    const deviceVersion = this.devicesService.getSemanticVersion(device?.app_version);

    const monocularColor = this.form?.value?.monocular_color;

    csuiteIndexes.reverse().forEach(position => {
      this.bundleRequestModel.test_descriptions.splice(position, 1,
        ...this.testBundlesService.getCSuiteDescriptions(
          this.bundleRequestModel.test_descriptions[position],
          deviceVersion,
          this.controlTypes,
          this.getFirstConvergenceMonocularValue(),
          monocularColor));
    });
  }

  private resetPendingBundleItems() {
    if (this.bundledTests.length >= 2)
      this.isResetItemsMessageDisplayed = true;
    this.bundledTests = [];
  }

  private removeHintMessages() {
    this.isResetItemsMessageDisplayed = false;
    this.testFormService.hideGroupVisualHint();
    this.testFormService.hideStrategyVisualHint();
    this.testFormService.hideLanguageVisualHint();
  }

  isBackDisabled() {
    if (!this.isNew)
      return this.isFirstStep;
    return !this.form?.valid || (this.isFirstStep && !this.newTestPromptShown);
  }

  isForwardDisabled() {
    if (!this.isNew)
      return this.isLastStep;
    return !this.form?.valid || this.isLastStep;
  }

  get isLastStep(): boolean {
    return this.currentBundleStep >= this.bundledTests.length;
  }

  get isFirstStep(): boolean {
    return this.currentBundleStep <= 1;
  }
}
