// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { OfficesService } from 'app/core/services/offices.service';
import { UsersService } from 'app/core/services/users.service';
import { UtilityService } from 'app/core/services/utility.service';

// models
import { IEmployee, Office, CenterContainerTitle } from '../../models';

// constants
import { USER_GROUP } from '../../constants';

@Component({
  selector: 'app-assign-admin-form',
  templateUrl: './assign-admin-form.component.html'
})
export class AssignAdminFormComponent implements OnInit {

  form: UntypedFormGroup;
  users: IEmployee[];
  office: Office;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private officesService: OfficesService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    const officeId = this.route.snapshot.params['officeId'];

    this.translateService.get('admin').subscribe(() => this.setTranslations());

    this.isLoading = true;
    this.officesService.getOne(officeId).subscribe((office: Office) => {
      if (!office)
        this.close();

      this.office = office;

      this.usersService.getAll(officeId).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(users => {
        this.users = users.filter(user => this.canDisplayUser(user));
        this.initializeForm();
      }, err => this.close());

    }, err => this.close());
  }

  private canDisplayUser(user: IEmployee): boolean {
    if (user.groups?.includes(USER_GROUP.OFFICE_GROUP_ADMIN.value) || user.administrated_offices?.includes(this.office.id))
      return false;
    return !!user.email && !!user.first_name && !!user.last_name;
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('admin');
    this.title.thin = this.translateService.instant('assign');
  }

  public initializeForm() {
    this.form = this.formBuilder.group({
      user: [this.users, Validators.required],
    });
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    let { user } = this.form.value;

    this.isLoading = true;
    this.officesService.setOfficeAdmin(this.office.id, user.id).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(() => {
      const selectedUser = this.users.find(u => u.id === user.id);
      const message = `<b>${this.utilityService.escapeHTML(selectedUser.first_name)} ${this.utilityService.escapeHTML(selectedUser.last_name)}</b>
      ${this.translateService.instant('assignAdminSuccess')} <b>${this.utilityService.escapeHTML(this.office.name)}.</b>`;
      this.toastService.success(message, null, {
        enableHtml: true
      });
      this.close();
    },
      response => {
        response && response.error && this.toastService.error(response.error);
      }
    );
  }

  close() {
    this.router.navigate(['/offices']);
  }
}
