<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [isRegionShown]="isRegionShown"
    [currentRegion]="currentRegion"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <ng-container *ngIf="!isSimpleLayoutShown, else simpleLayoutTemplate">
    <div
      *ngIf="isPlotShown"
      class="monitor_container-column"
      [ngClass]="{ 'monitor_container-column--single' : isGraphOnlyShown }"
      (window:resize)="windowChanged.emit(getEvent())">
      <div 
        class="monitor_graph-box"
        [ngClass]="{ 'monitor_graph-box--single' : isGraphOnlyShown }"
        #plotContainer>
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
        <div class="monitor_graph-title monitor_graph-title--right" *ngIf="authService.isOneHundredBetaLevel">
          <i 
            class="fas fa-border-all"
            [ngClass]="{ 'grey': !isGridShown }"
            (click)="onToggleGrid()">
          </i>
        </div>

        <plotly-plot [data]="odData" *ngIf="layoutRight" [layout]="layoutRight" [config]="monitorTestService.plotOptions"
          [revision]="odCount" [debug]="true" class="monitor_plot-box">
        </plotly-plot>
      </div>
      <div 
        class="monitor_graph-box" 
        [ngClass]="{ 'monitor_graph-box--single' : isGraphOnlyShown }">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
        <div class="monitor_graph-title monitor_graph-title--right" *ngIf="authService.isOneHundredBetaLevel">
          <i 
            class="fas fa-border-all"
            [ngClass]="{ 'grey': !isGridShown }"
            (click)="onToggleGrid()">
          </i>
        </div>

        <plotly-plot [data]="osData" *ngIf="layoutLeft" [layout]="layoutLeft" [config]="monitorTestService.plotOptions"
          [revision]="osCount" [debug]="true" class="monitor_plot-box">
        </plotly-plot>
      </div>
    </div>

    <div *ngIf="!isGraphOnlyShown" class="monitor_container-column">
      <div class="monitor_graph-box">
        <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <app-percentage-circle
          id="test-percentage-right"
          class="monitor_circle-box" 
          [ngClass]="{ 'monitor_circle-box--no-plot': !isPlotShown }"
          [percentage]="odEyeStats.completionPercentage?.toFixed(0)">
        </app-percentage-circle>
      </div>
      <div class="monitor_graph-box">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
        <app-percentage-circle
          id="test-percentage-left"
          class="monitor_circle-box" 
          [ngClass]="{ 'monitor_circle-box--no-plot': !isPlotShown }"
          [percentage]="osEyeStats.completionPercentage?.toFixed(0)">
        </app-percentage-circle>
      </div>
    </div>

    <div *ngIf="!isGraphOnlyShown" class="monitor_container-column">
      <div class="monitor_graph-box">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
        <div class="monitor_stat-container" [ngClass]="{ 'monitor_stat-container--no-plot': !isPlotShown }">

          <div class="monitor_stat-box">
            <app-percentage-squares
              [percentage]="odEyeStats.totalViewedCount > 3 ? ((odEyeStats.falsePositiveCount / odEyeStats.totalProcessedCount) * 100).toFixed(0) : 0"
              [color]="'#54b17c'"
              [id]="'od'">
            </app-percentage-squares>
            <span class="monitor_stat-title">FP</span> 
            <span class="monitor_stat-title">
              {{ odEyeStats.totalViewedCount > 3 ? ((odEyeStats.falsePositiveCount / odEyeStats.totalProcessedCount) * 100).toFixed(0) : 0 }}
              <span class="text--sm">&#37;</span>
            </span>
          </div>
    
          <div class="monitor_stat-box">
            <app-percentage-squares
              [percentage]="monitorTestService.getFalseNegativePercentage(odEyeStats.falseNegativeCount)"
              [color]="'#afcb53'"
              [id]="'od'">
            </app-percentage-squares>
            <span class="monitor_stat-title">FN</span> 
            <span class="monitor_stat-title">
              {{ odEyeStats.falseNegativeCount }}
            </span>
          </div>
    
          <div class="monitor_stat-box">
            <app-percentage-squares
              [percentage]="monitorTestService.getFixationLossPercentage(odEyeStats.blindspotCount)"
              [color]="'#f6c042'"
              [id]="'od'">
            </app-percentage-squares>
            <span class="monitor_stat-title">FL</span> 
            <span class="monitor_stat-title">
              {{ odEyeStats.blindspotCount }}
            </span>
          </div>
        </div>
      </div>

      <div class="monitor_graph-box">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>

        <div class="monitor_stat-container" [ngClass]="{ 'monitor_stat-container--no-plot': !isPlotShown }">
          <div class="monitor_stat-box">
            <app-percentage-squares
              [percentage]="osEyeStats.totalViewedCount > 3 ? ((osEyeStats.falsePositiveCount / osEyeStats.totalProcessedCount) * 100).toFixed(0) : 0"
              [color]="'#54b17c'"
              [id]="'os'">
            </app-percentage-squares>
            <span class="monitor_stat-title">FP</span> 
            <span class="monitor_stat-title">
              {{ osEyeStats.totalViewedCount > 3 ? ((osEyeStats.falsePositiveCount / osEyeStats.totalProcessedCount) * 100).toFixed(0) : 0 }}
              <span class="text--sm">&#37;</span>
            </span>
          </div>
    
          <div class="monitor_stat-box">
            <app-percentage-squares
              [percentage]="monitorTestService.getFalseNegativePercentage(osEyeStats.falseNegativeCount)"
              [color]="'#afcb53'"
              [id]="'os'">
            </app-percentage-squares>
            <span class="monitor_stat-title">FN</span> 
            <span class="monitor_stat-title">
              {{ osEyeStats.falseNegativeCount }}
            </span>
          </div>
    
          <div class="monitor_stat-box">
            <app-percentage-squares
              [percentage]="monitorTestService.getFixationLossPercentage(osEyeStats.blindspotCount)"
              [color]="'#f6c042'"
              [id]="'os'">
            </app-percentage-squares>
            <span class="monitor_stat-title">FL</span> 
            <span class="monitor_stat-title">
              {{ osEyeStats.blindspotCount }}
            </span>
          </div>

        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #simpleLayoutTemplate>
    <div class="monitor_container-column">
      <div class="monitor_graph-box">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
        <div class="monitor_eye-box">
          <span class="text--blink">{{isTestDone ? ('testDone' | translate | titlecase) : (('inProgress' | translate | titlecase) + '...') }}</span>
        </div>
      </div>
    
      <div class="monitor_graph-box">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
        <div class="monitor_eye-box">
          <span class="text--blink">{{isTestDone ? ('testDone' | translate | titlecase) : (('inProgress' | translate | titlecase) + '...') }}</span>
        </div>
      </div>
    </div>

    <div class="monitor_container-column">
      <div class="monitor_graph-box monitor_graph-box--single">
        <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
        <app-percentage-circle
          id="test-percentage"
          class="monitor_circle-box" 
          [percentage]="isTestDone ? 100 : 0">
        </app-percentage-circle>
      </div>
    </div>
  </ng-template>
</div>