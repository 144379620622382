<div class="volume-control_container">
  <span class="pointer" (click)="toggleMute()">
    <i class="fa fa-volume-{{isMuted ? 'mute' : 'up'}}"></i>
  </span>

  <ngx-slider
    [(value)]="volume"
    [options]="options"
    (userChangeEnd)="onVolumeChange($event)">
  </ngx-slider>

  <span class="ipd_container">
    <i 
      *ngIf ="ipd" 
      class="fas fa-eye ipd_icon"
      matTooltip="IPD: {{ ipd }}">
    </i>
  </span>
</div>