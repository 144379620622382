// native
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { CoverState, MonitorEvent, PlotData } from '../../models';

// constants
import { MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT, STRATEGY, TESTING_POSITIONS } from '../../constants';

@Component({
  selector: 'app-monitor-cover',
  templateUrl: './monitor-cover.component.html'
})
export class MonitorCoverComponent extends MonitorBaseComponent {

  isPartOfSensorimotorSuite: boolean = false;
  state: CoverState = null;
  inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.COVER * 1000;

  plotData: PlotData[] = [];
  layout;
  count: number = 0;
  testingPositions;

  @ViewChild('monitorContainer') monitorContainer: ElementRef;
  @ViewChild('plotContainer') plotContainer: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService,
      patientsService, monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.isPartOfSensorimotorSuite = this.test.test_group?.strategy?.value === STRATEGY.COVER_SENSORIMOTOR;

    this.plotData = [{ ...this.monitorTestService.getEomsInitialPlotData() }];

    this.monitorTestService.getCoverState(this.test.id).subscribe(state => {
      this.state = state;
      if (this.isPartOfSensorimotorSuite) {
        this.updateGraph(state);
        this.calculateDimensions();
      }
    });

    if (this.isPartOfSensorimotorSuite)
      this.testingPositions = Object.values(TESTING_POSITIONS).find(position => position.value === this.test.cover_directions)?.label;
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_COVER_STATE) {
      const eventSeqNum = (<CoverState>event.data).seq_num;
      if (!eventSeqNum || !this.state?.seq_num || (this.state.seq_num <= eventSeqNum)) {
        this.updateGraph(<CoverState>event.data);
        this.state = (<CoverState>event.data);
      }
    }
  }

  private updateGraph(state: CoverState) {
    let existingSnapshot: PlotData = { ...this.plotData[0] };

    const currentColor = 2;
    const finishedColor = 1.5;

    const newShapshot = { ...existingSnapshot };

    if (state.current_direction) {
      newShapshot.marker.color[state.current_direction] = currentColor;
      newShapshot.x[state.current_direction] = this.monitorTestService.coverSensorimotorXCoords[state.current_direction];
      newShapshot.y[state.current_direction] = this.monitorTestService.coverSensorimotorYCoords[state.current_direction];
    }

    state.finished_directions?.forEach(direction => {
      newShapshot.marker.color[direction] = finishedColor;
      newShapshot.x[direction] = this.monitorTestService.coverSensorimotorXCoords[direction];
      newShapshot.y[direction] = this.monitorTestService.coverSensorimotorYCoords[direction];
    });

    const updatedSnapshot = {
      ...newShapshot,
      marker: {
        ...newShapshot.marker,
        color: [...newShapshot.marker?.color]
      },
      x: [...newShapshot.x],
      y: [...newShapshot.y],
      text: [...newShapshot.text]
    };

    this.plotData = [updatedSnapshot];
    this.count++;
  }

  public calculateDimensions() {
    const dimensions = this.monitorTestService.getDoublePlotDimensions(this.monitorContainer.nativeElement, this.plotContainer.nativeElement);

    this.layout = { ...this.monitorTestService.eomsLayout, ...dimensions };
  }

  public resetStateAfterReconnection(): void { }

  public onToggleGrid() { }
}
