// native
import { map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';

// services
import { ApiService } from './api.service';
import { PreferencesService } from './preferences.service';
import { ZeroPiiService } from './zero-pii.service';
import { UtilityService } from './utility.service';

// models
import { Patient, Gender, Ethnicity, PatientRequest, PaginatedItems, DemoPatientRequest } from '../../models';

// constants
import {
  API_ETHNICITIES_PATH, API_GENDERS_PATH, API_PATIENTS_PATH,
  API_DEMO_PATIENT_PATH, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, DEFAULT_ENCRYPTED_PAGE_SIZE
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  constructor(
    private apiService: ApiService,
    private preferencesService: PreferencesService,
    private zeroPiiService: ZeroPiiService,
    private utilityService: UtilityService
  ) { }

  getAll(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null, review: boolean = null): Observable<PaginatedItems<Patient>> {
    let path = `${API_PATIENTS_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    if (review !== null)
      path = path + `&needs_match_review=${review}`;

    if (this.preferencesService.isZeroPiiEnabled) {
      return this.apiService.get(path).pipe(
        mergeMap((paginatedPatients: PaginatedItems<Patient>) => from(this.decryptPaginatedPatients(paginatedPatients)))
      );
    }

    return this.apiService.get(path) as Observable<PaginatedItems<Patient>>;
  }

  getOne(id: number): Observable<Patient> {
    if (this.preferencesService.isZeroPiiEnabled) {
      return this.apiService.get(this.getPatientUrl(id)).pipe(
        mergeMap((patient: Patient) => {
          if (!patient?.is_encrypted)
            return of(patient);
          return from(this.decryptPatient(patient));
        }));
    }

    return this.apiService.get(this.getPatientUrl(id)).pipe(
      map(res => res as Patient));
  }

  getDuplicate(body: PatientRequest): Promise<Patient> {
    let path = `${API_PATIENTS_PATH}?date_of_birth=${body.date_of_birth}`;
    if (this.preferencesService.disablePi)
      path = path + `&patient_id_number=${body.patient_id_number}`;
    else
      path = path + `&first_name=${body.first_name}&last_name=${body.last_name}`;

    return this.apiService.get(path).pipe(
      map((res: PaginatedItems<Patient>) => res?.results ? res.results[0] : null)
    ).toPromise();
  }

  create(body: PatientRequest): Observable<Patient> {
    if (this.preferencesService.isZeroPiiEnabled)
      return from(this.encryptPatientRequest(body)).pipe(
        mergeMap(encryptedBody => this.apiService.post(API_PATIENTS_PATH, encryptedBody)),
        map(res => res as Patient),
        mergeMap(patient => from(this.decryptPatient(patient))),
        tap(patient => this.zeroPiiService.savePatient(patient))
      );

    return this.apiService.post(API_PATIENTS_PATH, body).pipe(
      map(res => res as Patient));
  }

  delete(id: number): Observable<void> {
    if (this.preferencesService.isZeroPiiEnabled) {
      return this.apiService.delete(this.getPatientUrl(id)).pipe(
        map(res => null),
        tap(() => this.zeroPiiService.deletePatient(id)));
    }

    return this.apiService.delete(this.getPatientUrl(id)).pipe(map(res => null));
  }

  update(id: number, body: PatientRequest): Observable<Patient> {
    if (this.preferencesService.isZeroPiiEnabled)
      return from(this.encryptPatientRequest(body)).pipe(
        mergeMap(encryptedBody => this.apiService.put(this.getPatientUrl(id), encryptedBody)),
        map(res => res as Patient),
        mergeMap(patient => from(this.decryptPatient(patient))),
        tap(patient => this.zeroPiiService.savePatient(patient))
      );

    return this.apiService.put(this.getPatientUrl(id), body).pipe(
      map(res => res as Patient));
  }

  merge(sourceId: number, destId: number): Observable<null> {
    return this.apiService.post(`${API_PATIENTS_PATH}${sourceId}/merge_into/`, { patient: destId }).pipe(
      map(res => res as null));
  }

  getEthnicities(): Observable<Ethnicity[]> {
    return this.apiService.get(API_ETHNICITIES_PATH).pipe(map(obj => obj as Ethnicity[]));
  }

  getGenders(): Observable<Gender[]> {
    return this.apiService.get(API_GENDERS_PATH).pipe(map(obj => obj as Gender[]));
  }

  createDemoPatient(body: DemoPatientRequest): Observable<Patient> {
    return this.apiService.post(API_DEMO_PATIENT_PATH, body).pipe(
      map(res => res as Patient));
  }

  getDisplayName(patient: Patient): string {
    return this.preferencesService.disablePi
      ? patient?.patient_id_number
        ? `${patient?.patient_id_number}`
        : `${patient?.first_name} ${patient?.last_name}`
      : patient?.first_name || patient?.last_name
        ? `${patient?.first_name} ${patient?.last_name}`
        : `${patient?.patient_id_number}`;
  }

  private getPatientUrl(id: number) {
    return `${API_PATIENTS_PATH}${id}/`;
  }

  private async decryptPaginatedPatients(patients: PaginatedItems<Patient>): Promise<PaginatedItems<Patient>> {
    return Promise.all(
      patients.results.map(async patient => {
        if (!patient.is_encrypted)
          return patient;
        return await this.decryptPatient(patient);
      })
    ).then(([...decrypted]) => {
      return {
        results: [...decrypted],
        count: patients.count
      };
    });
  }

  async decryptPatient(patient: Patient): Promise<Patient> {
    const encryptedData = patient.encrypted_pii;

    return Promise.all([
      this.zeroPiiService.decrypt(encryptedData.first_name),
      this.zeroPiiService.decrypt(encryptedData.last_name),
      this.zeroPiiService.decrypt(encryptedData.date_of_birth),
      this.zeroPiiService.decrypt(encryptedData.email),
      this.zeroPiiService.decrypt(encryptedData.phone)
    ]).then(([first_name, last_name, date_of_birth, email, phone]) => {
      const decryptedPatient: Patient = {
        ...patient,
        first_name,
        last_name,
        date_of_birth,
        email,
        phone,
        age: this.utilityService.calculateAge(date_of_birth)
      };
      return decryptedPatient;
    });
  }

  private async encryptPatientRequest(body: PatientRequest): Promise<PatientRequest> {
    const encryptedBody: PatientRequest = {
      ...body,
      encrypted_pii: {
      },
      is_encrypted: true,
    };

    if (body.first_name) {
      encryptedBody.encrypted_pii.first_name = await this.zeroPiiService.encrypt(body.first_name);
      encryptedBody.first_name = null;
    }

    if (body.last_name) {
      encryptedBody.encrypted_pii.last_name = await this.zeroPiiService.encrypt(body.last_name);
      encryptedBody.last_name = null;
    }

    if (body.date_of_birth) {
      encryptedBody.encrypted_pii.date_of_birth = await this.zeroPiiService.encrypt(body.date_of_birth);
      encryptedBody.date_of_birth = null;
    }
    if (body.email) {
      encryptedBody.encrypted_pii.email = await this.zeroPiiService.encrypt(body.email);
      encryptedBody.email = null;
    }

    if (body.phone) {
      encryptedBody.encrypted_pii.phone = await this.zeroPiiService.encrypt(body.phone);
      encryptedBody.phone = null;
    }

    return encryptedBody;
  }

  searchPatientStore(term: string): Observable<PaginatedItems<Patient>> {
    return from(this.zeroPiiService.loadPatients()).pipe(
      map(patients => {
        term = term.toLowerCase();
        return patients.filter(patient => patient.first_name?.toLowerCase().includes(term)
          || patient.last_name?.toLowerCase().includes(term));
      }),
      map(patients => patients.map(patient => patient.id)),
      mergeMap(patientIds => {
        if (!patientIds?.length)
          return of({ results: [], count: 0 });
        return this.apiService.get(`${API_PATIENTS_PATH}?id__in=${patientIds.join(',')}&limit=${DEFAULT_ENCRYPTED_PAGE_SIZE}`) as Observable<PaginatedItems<Patient>>;
      }),
      mergeMap((paginatedPatients: PaginatedItems<Patient>) => from(this.decryptPaginatedPatients(paginatedPatients))),
      // compare searched term with decrypted results from server in edge case something has changed on server
      map(patientsFromServer => {
        return {
          count: patientsFromServer.count,
          results: patientsFromServer.results.filter(patient => patient.first_name?.toLowerCase().includes(term.toLowerCase())
            || patient.last_name?.toLowerCase().includes(term.toLowerCase()))
        };
      })
    );
  }
}