// native
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private toastService: ToastrService,
    private translateService: TranslateService
  ) { }

  handleError(errorText?: string) {
    if (errorText && (typeof errorText === 'string'))
      return this.toastService.error(errorText);

    this.toastService.error(this.translateService.instant('somethingWentWrong'));
  }

  handleDeviceError(error: any) {
    if (error && error[0] === 'This device name was previously used in your office group, please choose a different one.')
      return this.toastService.error(this.translateService.instant('deviceDuplicatedErrorMessage'));

    if (error === 'UNLINK_WEEKLY_LIMIT_REACHED')
      return this.toastService.error(this.translateService.instant('deviceUnlinkLimitError'));

    this.handleError(error?.error);
  }

  handleOfficeError(error: any) {
    if (error?.code === 'ACTIVE_ASSETS_EXIST') {
      let message = this.translateService.instant('officeDeletionErrorBase');

      if (error.parameters?.number_of_devices)
        message = message.concat(`<br>${this.translateService.instant('officeDeletionErrorDevice')} <b>${error.parameters?.number_of_devices}</b>`);
      if (error.parameters?.number_of_employees)
        message = message.concat(`<br>${this.translateService.instant('officeDeletionErrorEmployee')} <b>${error.parameters?.number_of_employees}</b>`);
      if (error.parameters?.number_of_offline_controllers)
        message = message.concat(`<br>${this.translateService.instant('officeDeletionErrorController')} <b>${error.parameters?.number_of_offline_controllers}</b>`);

      return this.toastService.error(message, null, { enableHtml: true });
    }

    this.handleError(error);
  }

  handleTestError(error: any) {
    if (!error)
      this.handleError();

    if (error === 'Test parameters invalid.')
      return this.toastService.error(this.translateService.instant('testCreationErrorGeneric'));

    if (error.test_descriptions) {
      if (error.test_descriptions.some(desc => desc === 'test_descriptions must contain no more than 10 items.'))
        return this.toastService.error(this.translateService.instant('bundleCreationFailed'));
    }

    if (error.non_field_errors) {
      if (error.non_field_errors.some(desc => desc === 'Cannot create more than 10 custom test bundle types.'))
        return this.toastService.error(this.translateService.instant('customBundleCreationFailed'));
    }

    if (typeof error === 'string')
      this.toastService.error(error);
  }

  handleUserError(form: UntypedFormGroup, error: any) {
    // errors creating new user
    if (error?.username?.includes('This username is taken, please use another one.'))
      form.get('username').setErrors({ usernameTaken: true });

    if (error?.email?.includes('This email address is taken, please use another one.'))
      form.get('email').setErrors({ emailTaken: true });

    if (error?.phone_number?.includes('This field must be unique.') || error?.phone_number?.includes('employee with this phone number already exists.'))
      form.get('phone_number').setErrors({ phoneTaken: true });

    // errors editing existing user
    if (Array.isArray(error) && error.includes('Username must be unique.'))
      form.get('username').setErrors({ usernameTaken: true });

    if (Array.isArray(error) && error.includes('Email must be unique.'))
      form.get('email').setErrors({ emailTaken: true });

    if (typeof error?.error === 'string' && error.error.includes('Unable to change the role: Doctor is in use.'))
      return this.toastService.error(this.translateService.instant('doctorRoleChangeError'));

    if (typeof error?.error === 'string' && error.error.includes('Unable to change the role: Operator is in use.'))
      return this.toastService.error(this.translateService.instant('operatorRoleChangeError'));

    this.toastService.error(this.translateService.instant('userSettingsValidationGeneric'));
  }

  handleSupportUserError(error: string) {
    if (error === 'EMAIL_IN_USE')
      return this.toastService.error(this.translateService.instant('emailInUse'));

    if (error === 'OGA_UPDATES_WEEKLY_LIMIT_REACHED')
      return this.toastService.error(this.translateService.instant('ogaUpdateLimitError'));

    if (error === 'USER_EMAIL_UPDATES_WEEKLY_LIMIT_REACHED')
      return this.toastService.error(this.translateService.instant('userEmailUpdateLimitError'));

    this.handleError(error);
  }

  handleInvitationError(form: UntypedFormGroup, error: any) {
    if (error?.email?.includes('This email address is taken, please use another one.'))
      form.get('email').setErrors({ emailTaken: true });

    this.toastService.error(this.translateService.instant('invitationSentErrorMessage'));
  }

  handleAuthErrors(error: string) {
    if (error = 'sso_required')
      return this.toastService.error(this.translateService.instant('ssoRequiredErrorMessage'));

    if (error.includes('User login is blocked, try again in'))
      return this.toastService.error(this.translateService.instant('loginBlockedErrorMessage'));

    if (['Duo authentication failed.', '2FA verification failed. Contact you administrator.', '2FA Unavailable. Contact you administrator.'].includes(error))
      return this.toastService.error(this.translateService.instant('2faFailedErrorMessage'));

    if (error = 'WebAuthn authentication failed.')
      return this.toastService.error(this.translateService.instant('deviceAuthFailedErrorMessage'));

    if (['unauthorized', 'fail'].includes(error))
      return this.handleError(null);

    return this.handleError(null);
  }

  handleServerErrors(error: string) {
    if (['Duo authentication failed.', '2FA verification failed. Contact you administrator.', '2FA Unavailable. Contact you administrator.'].includes(error))
      return this.toastService.error(this.translateService.instant('2faFailedErrorMessage'));

    if (error = 'An error occurred during report generation. Please try again later.')
      return this.toastService.error(this.translateService.instant('reportGenerationErrorMessage'));

    if (error = 'Error during test creation')
      return this.toastService.error(this.translateService.instant('testCreationErrorGeneric'));

    return this.handleError(null);
  }
}